import React, { useEffect } from "react"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"

const NotFoundPage = ({ setTransparentNav, transparentNav }) => {
  const [ref, inView] = useInView({
    threshold: 0.75,
    initialInView: true,
  })
  useEffect(() => {
    if (inView) {
      setTransparentNav(true)
    } else {
      setTransparentNav(false)
    }
  }, [inView])
  return (
    <>
      <Seo title="404: Not Found" />
      <div className={`page-not-found`}>
        <div className={`page-heading`} ref={ref}>
          <div className={`overlay`}>
            <h1>404: Not Found</h1>
          </div>
        </div>
        <div className={`page-content`}>
          <div className={`text-box`}>
            <h2>Page not found...</h2>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
